@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

  /* Body */
  
  body {
    @apply bg-white text-main-950
    dark:bg-main-950 dark:text-white
    transition-[background-color] duration-150 ease-in-out;
  }



  /* Typography */
  
  h1, h2, h3, h4, h5, h6 {
    @apply font-bold;
  }
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-2xl;
  }
  h3 {
    @apply text-xl;
  }
  h4 {
    @apply text-lg;
  }
  h5 {
    @apply text-base;
  }
  h6 {
    @apply text-sm;
  }



  /* Nav */

  nav {
    @apply fixed top-0 left-0 right-0 border-b z-10
    bg-main-50 border-main-200
    dark:bg-main-900 dark:border-main-700;
  }
  nav .nav-container {
    @apply relative max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl px-4 h-16 mx-auto grid grid-cols-[auto,1fr] place-items-center;
  }

  nav .nav-container .logo {
    @apply focus:outline-none
    text-main-800 hover:text-main-700 focus:text-main-700
    dark:text-main-100 dark:hover:text-main-200 dark:focus:text-main-200
    transition-colors duration-150 ease-in-out;

  }

  .theme-switch-container {
    @apply flex items-center px-4;
  }
  .theme-switch {
    @apply relative w-12 h-6 rounded-xl cursor-pointer border focus:outline-none focus:ring-2
    bg-white border-main-200 focus:ring-main-400
    dark:bg-main-950 dark:border-main-700 focus:dark:ring-main-500
    transition-[box-shadow] duration-150 ease-in-out;
  }
  .theme-switch .theme-switch-btn {
    @apply absolute top-[-0.25rem] left-[-0.25rem] w-8 h-8 rounded-full dark:translate-x-6 border flex items-center justify-center
    bg-main-50 border-main-300
    dark:bg-main-900 dark:border-main-600
    transition-transform duration-300 ease-in-out;
  }
  .theme-switch .theme-switch-btn .theme-switch-icon {
    @apply absolute
    text-main-400
    dark:text-main-500
    transition-opacity duration-300 ease-in-out;
  }
  .theme-switch .theme-switch-btn .theme-switch-icon.light-icon {
    @apply opacity-100 dark:opacity-0;
  }
  .theme-switch .theme-switch-btn .theme-switch-icon.dark-icon {
    @apply opacity-0 dark:opacity-100;
  }

  nav .nav-container .nav-items .theme-switch-container {
    @apply hidden md:flex;
  }
  nav .nav-container .nav-items .mobile-header .theme-switch-container {
    @apply flex md:hidden;
  }

  .nav-container .menubtn-container {
    @apply w-full h-full flex items-center justify-end
    md:hidden;
  }
  .nav-container .menubtn {
    @apply w-12 h-12 flex items-center justify-center text-2xl
    text-main-900
    dark:text-main-50;
  }

  .nav-container .nav-items {
    @apply flex z-20
    fixed flex-col top-0 right-0 bottom-0 w-full sm:w-[90vw] sm:border-l translate-x-[100%]
    bg-white border-main-200
    dark:bg-main-950 dark:border-main-700
    md:relative md:flex-row md:w-full md:h-full md:justify-end md:border-none md:translate-x-0
    md:bg-transparent
    md:dark:bg-transparent
    transition-transform duration-300 ease-in-out;
  }
  .nav-container .nav-items.open {
    @apply translate-x-0;
  }
  
  .nav-container .nav-items .mobile-header {
    @apply flex items-center justify-between w-full h-16 border-b px-4 mb-4
    bg-main-50 border-main-200
    dark:bg-main-900 dark:border-main-700
    md:hidden;
  }

  .nav-container .nav-items .nav-item {
    @apply relative px-4 focus:outline-none
    md:leading-[4rem] md:align-middle md:h-full
    md:hover:text-main-400 focus:text-main-400
    md:hover:dark:text-main-500 focus:dark:text-main-500
    transition-colors duration-150 ease-in-out;
  }
  .nav-container .nav-items .nav-item.active {
    @apply 
    text-main-400
    dark:text-main-500
    md:after:content-[''] md:after:absolute md:after:bottom-[-1px] md:after:right-0 md:after:left-0 md:after:h-[2px]
    md:after:shadow-[0_-1px_3px]
    md:text-main-950 md:after:bg-main-400 md:after:shadow-main-400
    md:dark:text-white md:after:dark:bg-main-500 md:after:dark:shadow-main-500
    md:hover:text-main-400 focus:text-main-400
    md:hover:dark:text-main-500 focus:dark:text-main-500;
  }

  nav .nav-container .sidebar-bg {
    @apply fixed top-0 left-0 right-0 bottom-0 hidden opacity-80 z-10
    md:hidden
    bg-main-50
    dark:bg-main-900;
  }

  nav .nav-container .sidebar-bg.open {
    @apply block md:hidden;
  }

  body:has(.sidebar-bg.open) {
    @apply overflow-hidden md:overflow-auto;
  }



  /* Footer */
  footer {
    @apply absolute flex flex-col items-center justify-center translate-y-[-5rem] w-full h-20 border-t
    bg-main-50 border-main-200
    dark:bg-main-900 dark:border-main-700
    transition-[background-color,border-color] duration-150 ease-in-out;
  }
  footer p {
    @apply text-center
    transition-[color] duration-150 ease-in-out;
  }
  footer .footer-btns {
    @apply flex gap-4 items-center;
  }
  footer .footer-btns .footer-btn {
    @apply focus:outline-none text-xl
    text-main-300 hover:text-main-500 focus:text-main-500
    dark:text-main-700 hover:dark:text-main-600 focus:dark:text-main-600
    transition-[color] duration-150 ease-in-out;
  }



  /* Containers */

  .container {
    @apply max-w-screen-xl mx-auto p-4 min-h-[100vh]; 
  }
  nav + .container {
    @apply pt-20;
  }
  body:has(footer) .container {
    @apply pb-24;
  }

  .card {
    @apply rounded-lg border bg-transparent overflow-hidden
    border-main-200
    dark:border-main-700;
  }
  .card .card-header {
    @apply p-4
    bg-main-50 bg-opacity-50
    dark:bg-main-900 dark:bg-opacity-50;
  }
  .card .card-header + .card-body {
    @apply border-t
    border-main-200
    dark:border-main-700;
  }
  .card .card-body {
    @apply p-4
    bg-main-50 bg-opacity-20
    dark:bg-main-900 dark:bg-opacity-20;
  }
  .card .card-body + .card-footer {
    @apply border-t
    border-main-200
    dark:border-main-700;
  }
  .card .card-footer {
    @apply p-4
    bg-main-50 bg-opacity-50
    dark:bg-main-900 dark:bg-opacity-50;
  }



  /* Buttons */

  .btn {
    @apply uppercase px-4 py-2 flex items-center justify-center gap-2 rounded-lg font-bold focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed
    transition-opacity duration-150 ease-in-out;
  }

  .btn-main {
    @apply focus:outline-none focus:ring-2
    bg-main-500 hover:bg-main-300 disabled:bg-main-500 focus:ring-main-400
    dark:bg-main-600 hover:dark:bg-main-700 disabled:dark:bg-main-600 focus:dark:ring-main-500
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }
  .btn-outline-main {
    @apply bg-transparent border focus:outline-none focus:ring-2
    text-main-500 border-main-500 hover:bg-main-500 hover:text-main-950 focus:ring-main-400
    dark:text-main-600 dark:border-main-600 hover:dark:bg-main-600 hover:dark:text-white focus:dark:ring-main-500
    disabled:bg-transparent disabled:text-main-500
    disabled:dark:bg-transparent disabled:dark:text-main-600
    transition-[background-color,color,box-shadow] duration-150 ease-in-out;
  }



  /* Forms */

  .form-group {
    @apply flex flex-col gap-2;
  }

  .form-group.error label {
    @apply text-red-600
    dark:text-red-500
  }
  .form-group.error .form-control {
    @apply border-red-600 focus:ring-red-500
    dark:border-red-500 focus:dark:ring-red-600;
  }

  .form-group label {
    @apply relative
    transition-[color] duration-150 ease-in-out;;
  }
  .form-group.required label {
    @apply after:relative after:content-["_*"]
    after:text-red-600
    after:dark:text-red-500
    after:transition-[color] duration-150 ease-in-out;
  }
  .form-group.recommended label {
    @apply after:relative after:content-["_(Recommended)"]
    after:text-main-500
    after:dark:text-main-600
    after:transition-[color] duration-150 ease-in-out;
  }

  .form-control {
    @apply px-2 rounded-lg border focus:outline-none focus:ring-2
    text-main-950 bg-white border-main-200 focus:ring-main-400
    dark:text-white dark:bg-main-950 dark:border-main-700 focus:dark:ring-main-500
    transition-[background-color,color,border-color,box-shadow,opacity] duration-150 ease-in-out;
  }

  .form-control:disabled {
    @apply opacity-50 cursor-not-allowed;
  }

  .input-group {
    @apply grid grid-cols-[1fr,2fr];
  }
  .input-group-reverse {
    @apply grid-cols-[2fr,1fr]
  }
  .input-group-3 {
    @apply grid grid-cols-[1fr,2fr,1fr];
  }
  .input-group .form-control {
    @apply border-l-0 rounded-none w-full;
  }
  .input-group .form-control:first-child {
    @apply border-l rounded-l-lg;
  }
  .input-group .form-control:last-child {
    @apply rounded-r-lg;
  }
}